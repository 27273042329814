import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { optionService } from 'services';
import { Button, ButtonTypes, Input, Paper, Select, updateFormErrors } from '@bs/techconnect-ui';
import { useSuccess } from 'helpers';
import { OPTION_FORM, OPTION_TYPES } from '../helpers';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { ReactComponent as Remove } from 'icons/cross_s.svg';
import { ReactComponent as Add } from 'icons/plus_xs.svg';
import { ReactComponent as Back } from 'icons/chevron.svg';

import styles from './OptionsForm.module.css';

const OptionsForm = () => {
  const navigate = useNavigate();
  const { optionId } = useParams();
  const isCreate = optionId === 'new';
  const location = useLocation();
  const { option } = location.state || {};
  const { setSuccess } = useSuccess();

  const [exitModal, setExitModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newValue, setNewValue] = useState('');

  const {
    register,
    formState: { errors, isValid, isDirty },
    control,
    watch,
    reset,
    handleSubmit,
    setError,
  } = useForm({
    mode: 'onTouched',
  });

  const { fields, append, remove } = useFieldArray({ control, name: 'allowedValues' });
  const optionType = watch('type');
  const isListType = optionType === OPTION_TYPES.L;

  useEffect(() => {
    if (!isCreate) {
      const { allowedValues, ...rest } = option;
      reset(rest);
      append(
        allowedValues.map(value => ({
          value: value,
        })),
      );
    }
  }, [option, reset, append, isCreate]);

  const addNewValue = () => {
    if (newValue) {
      append({ value: newValue });
      setNewValue('');
    }
  };

  const submit = form => {
    const { allowedValues, ...rest } = form;
    const addedValues = isListType ? allowedValues.map(({ value }) => value) : [];
    const body = { ...rest, allowedValues: addedValues };
    const method = isCreate ? optionService.createOption : optionService.updateOption;
    setLoading(true);
    method(body, optionId)
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data, form, setError);
        if (status === 200) {
          setSuccess(`${isCreate ? 'Опция создана' : 'Изменения сохранены'} `);
          navigate('/options');
        }
      })
      .finally(() => {
        setLoading(false);
        reset(form);
      });
  };

  const close = () => {
    if (isDirty) {
      setExitModal(true);
    } else navigate('/options');
    reset({});
  };

  return (
    <>
      <Paper className={styles['paper']}>
        <header className={styles['header']}>
          <Button className={styles['back-btn']} variant={ButtonTypes.IR} onClick={close}>
            <Back />
          </Button>
          <h3>{isCreate ? 'Создание ' : 'Редактирование '} опции</h3>
          <Button className={styles['submit-btn']} disabled={!isValid} onClick={handleSubmit(submit)}>
            {isCreate ? `Создать опцию` : 'Сохранить изменения'}
          </Button>
        </header>
      </Paper>
      <div className={styles['fields-wrapper']}>
        {OPTION_FORM.map(({ key, title, options, list }) =>
          !list ? (
            <Input
              key={key}
              label={title}
              className={styles['create-field']}
              register={register(key, options)}
              error={errors[key]}
              disabled={loading}
            />
          ) : (
            <Controller
              key={key}
              control={control}
              name={key}
              rules={options}
              render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => {
                return (
                  <Select
                    className={styles['create-field']}
                    label={title}
                    list={list}
                    selected={value}
                    onBlur={onBlur}
                    error={error}
                    onSelect={onChange}
                  />
                );
              }}
            />
          ),
        )}
      </div>
      {isListType && (
        <div className={styles['values-wrapper']}>
          <header>Допустимые значения</header>
          <div className={styles['values-row']}>
            <div className={styles['value-add-wrapper']}>
              <Input
                className={styles['value-add']}
                label="Значение"
                value={newValue}
                onChange={({ target }) => setNewValue(target.value)}
                onEnter={addNewValue}
              />
              <Add onClick={addNewValue} />
            </div>
            {fields.map(({ value }, i) => (
              <div key={'row-value-' + value + i} className={styles['added-value']}>
                {value}
                <Remove onClick={() => remove(i)} />
              </div>
            ))}
          </div>
        </div>
      )}

      {<AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={() => navigate(`/options`)} />}
    </>
  );
};
export default OptionsForm;
