import { trimObject } from '@bs/techconnect-ui';
import { createBaseApi, configRequest, prepareQuery } from './baseAPI';

const baseApi = createBaseApi();

export const accessGroupService = {
  getAccessGroups,
  getAccessGroup,
  createAccessGroup,
  updateAccessGroup,
  deleteAccessGroup,

  getAccessGroupVehicles,
  postVehiclesToAccessGroup,
  deleteVehiclesFromAccessGroup,

  getAccessGroupUsers,
  postUsersToAccessGroup,
  deleteUsersFromAccessGroup,
};

function getAccessGroups(params = {}) {
  return baseApi.get(`/accessGroups?${prepareQuery(params)}`, configRequest);
}
function getAccessGroup(id) {
  return baseApi.get(`/accessGroups/${id}`, configRequest);
}
function createAccessGroup(body) {
  return baseApi.post('/accessGroups', trimObject(body), configRequest);
}
function updateAccessGroup(body, id) {
  return baseApi.put(`/accessGroups/${id}`, trimObject(body), configRequest);
}
function deleteAccessGroup(id) {
  return baseApi.delete(`/accessGroups/${id}`, configRequest);
}

function getAccessGroupVehicles(id, params = {}) {
  return baseApi.get(`/accessGroups/${id}/vehicles?${prepareQuery(params)}`, configRequest);
}
function postVehiclesToAccessGroup(id, body) {
  return baseApi.post(`/accessGroups/${id}/vehicles`, trimObject(body), configRequest);
}
function deleteVehiclesFromAccessGroup(id, data) {
  return baseApi.delete(`/accessGroups/${id}/vehicles`, { data }, configRequest);
}

function getAccessGroupUsers(id, params = {}) {
  return baseApi.get(`/accessGroups/${id}/users?${prepareQuery(params)}`, configRequest);
}
function postUsersToAccessGroup(id, body) {
  return baseApi.post(`/accessGroups/${id}/users`, trimObject(body), configRequest);
}
function deleteUsersFromAccessGroup(id, data) {
  return baseApi.delete(`/accessGroups/${id}/users`, { data }, configRequest);
}
