import { useCallback, useEffect, useState } from 'react';
import { NavLink, useSearchParams, useNavigate } from 'react-router-dom';

import { retranslationsService } from 'services';
import { EMPTY, useError, useSuccess } from 'helpers';
import { COLUMNS, STATE_ENUM } from './helpers';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import {
  ListFilters,
  pageSize,
  Table,
  Popper,
  Button,
  ButtonTypes,
  Indicator,
  IndicatorTypes,
} from '@bs/techconnect-ui';
import { ReactComponent as Stopped } from 'icons/pause.svg';
import { ReactComponent as Started } from 'icons/play.svg';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';

import styles from './Retranslations.module.css';

const Retranslations = () => {
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const { started, stopped } = STATE_ENUM;

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);

  const { getRetranslations, stopRetranslation, startRetranslation } = retranslationsService;
  const fetchRetranslations = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem(`retranslationsFilters`) || '{}') || {};
    const { filter = false, ...queryFilter } = filterData;
    setLoading(true);
    getRetranslations({ page, pageSize, ...(filter && queryFilter) })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(data.items);
          if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
        }
      })
      .finally(() => setLoading(false));
  }, [getRetranslations, page]);
  useEffect(() => {
    fetchRetranslations();
  }, [fetchRetranslations]);

  const startStopRetranslation = ({ id, state }) => {
    const isStarted = state === started;
    const method = isStarted ? stopRetranslation : startRetranslation;
    method(id).then(({ status }) => {
      if (status === 204) {
        setRows(prev => {
          return prev.map(r => {
            if (r.id !== id) return r;
            return { ...r, state: isStarted ? stopped : started };
          });
        });
        setSuccess(`Ретрансляция ${isStarted ? 'остановлена' : 'запущена'}`);
      } else {
        setError(`Не удалось ${isStarted ? 'остановить' : 'запустить'} ретрансляцию`);
      }
    });
  };

  const getStatus = state => {
    const isStarted = state === started;
    const { neutral, success } = IndicatorTypes;
    return <Indicator status={isStarted ? success : neutral}>{isStarted ? 'Запущена' : 'Остановлена'}</Indicator>;
  };

  const handler = row => ({
    ...row,
    serverPort: `${row?.server || EMPTY}, ${row?.port || EMPTY}`,
    state: getStatus(row.state),
    startStop: (
      <Popper popup={row.state === started ? 'Остановить' : 'Запустить'}>
        <Button variant={ButtonTypes.IT} onClick={() => startStopRetranslation(row)}>
          {row.state === started ? <Stopped /> : <Started />}
        </Button>
      </Popper>
    ),
    edit: (
      <Popper popup={'Перейти в карточку ретрансляции'}>
        <NavLink to={`/retranslations/${row.id}`}>
          <Button variant={ButtonTypes.IT}>
            <Edit />
          </Button>
        </NavLink>
      </Popper>
    ),
    delete: (
      <Popper popup={'Удалить'}>
        <Button variant={ButtonTypes.IT} onClick={() => setDeleteModal(row.id)}>
          <Delete />
        </Button>
      </Popper>
    ),
  });

  return (
    <>
      <ListFilters title="Ретрансляции" createHandler={() => navigate('/retranslations/new')} />

      <Table
        className={styles['table']}
        columns={COLUMNS}
        rows={rows}
        totalPages={totalPages}
        loading={loading}
        idKey="id"
        handler={handler}
      />

      <ConfirmModal
        open={deleteModal}
        setOpen={() => setDeleteModal(!deleteModal)}
        updateList={fetchRetranslations}
        method={() => retranslationsService.deleteRetranslation(deleteModal)}
        header="Удалить ретрансляцию?"
        description="Я понимаю, что восстановить ретрансляцию будет невозможно"
      />
    </>
  );
};

export default Retranslations;
