import { trimObject } from '@bs/techconnect-ui';

const { createBaseApi, configRequest, prepareQuery } = require('./baseAPI');

const baseApi = createBaseApi();

export const fleetService = {
  getFleets,
  getFleetById,
  createFleet,
  updateFleet,
  deleteFleet,
};

function getFleets(params = {}) {
  return baseApi.get(`/fleets?${prepareQuery(params)}`, configRequest);
}

function getFleetById(fleetId) {
  return baseApi.get(`/fleets/${fleetId}`, configRequest);
}
function createFleet(body) {
  return baseApi.post('/fleets', body, configRequest);
}

function updateFleet(body, fleetId) {
  return baseApi.put(`/fleets/${fleetId}`, trimObject(body), configRequest);
}

function deleteFleet(fleetId) {
  return baseApi.delete(`/fleets/${fleetId}`, configRequest);
}
