export const FILTERS = [
  { key: 'esn', title: 'ESN' },
  { key: 'iccid', title: 'ICCID' },
  { key: 'msisdn', title: 'MSISDN' },
  { key: 'deviceId', title: 'DeviceID' },
];

export const COLUMNS = [
  { key: 'id', title: 'DeviceId' },
  { key: 'esn', title: 'ESN' },
  { key: 'iccid', title: 'ICCID' },
  { key: 'protocol', title: 'Протокол' },
  { key: 'status', title: 'Статус' },
  { key: 'simState', title: 'Статус SIM чипа' },
  { key: 'environment', title: 'Окружение' },
  { key: 'hasLicense', title: 'Лицензия' },
  { key: 'edit', title: '' },
];

export const SIM_STATE_ENUM = {
  active: 'active',
  inactive: 'inactive',
};

export const DEVICE_INFO = [
  [
    { key: 'deviceId', title: 'DeviceId' },
    { key: 'esn', title: 'ESN' },
    { key: 'iccid', title: 'ICCID' },
  ],
  [
    { key: 'environment', title: 'Окружение' },
    { key: 'msisdn', title: 'MSISDN' },
    { key: 'simState', title: 'Статус SIM чипа' },
  ],
  [
    { key: 'imei', title: 'IMEI' },
    { key: 'status', title: 'Статус' },
    { key: 'protocol', title: 'Протокол' },
  ],
];

export const CARD_TABS = {
  numbers: 'Партномера',
  homeEndpoints: 'Home Endpoints',
  options: 'Значения опций',
  initialConfig: 'Initial Config Parameters',
};

export const DEVICE_DATA = {
  numbers: [
    [
      { key: 'moduleNumber', title: 'ModuleNumber' },
      { key: 'hardwareNumber', title: 'HardwareNumber' },
      { key: 'bootloaderNumber', title: 'BootloaderNumber' },
      { key: 'strategyNumber', title: 'StrategyNumber' },
      { key: 'calibrationNumber', title: 'CalibrationNumber' },
    ],
  ],
  homeEndpoints: [
    [
      { key: 'homeEndpoints.primaryHostName', title: 'PrimaryHostName' },
      { key: 'homeEndpoints.primaryGatewayHostName', title: 'PrimaryGatewayHostName' },
      { key: 'homeEndpoints.secondaryHostName', title: 'SecondaryHostName' },
      { key: 'homeEndpoints.secondaryGatewayHostName', title: 'SecondaryGatewayHostName' },
    ],
  ],
  options: [
    [
      { key: 'options.fuelDataSource', title: 'FuelDataSource' },
      { key: 'options.tankVolume', title: 'TankVolume' },
      { key: 'options.can1Availability', title: 'Can1Availability' },
      { key: 'options.can2Availability', title: 'Can2Availability' },
    ],
  ],
  initialConfig: [
    [
      { key: 'initialConfig.vin', title: 'VIN ' },
      { key: 'initialConfig.vendor', title: 'Vendor ' },
      { key: 'initialConfig.vehicleUsage', title: 'VehicleUsage' },
      { key: 'initialConfig.vehicleArchitecture', title: 'VehicleArchitecture' },
      { key: 'initialConfig.eventThresholds.drvBhvLongAccelThreshold', title: 'DrvBhvLongAccelThreshold' },
      { key: 'initialConfig.eventThresholds.drvBhvLatAccelThreshold', title: 'DrvBhvLatAccelThreshold' },
      { key: 'initialConfig.eventThresholds.drvBhvAltAccelThreshold', title: 'DrvBhvAltAccelThreshold' },
      { key: 'initialConfig.eventThresholds.drvBhvLongDecelThreshold', title: 'DrvBhvLongDecelThreshold' },
      { key: 'initialConfig.eventThresholds.drvBhvLatDecelThreshold', title: 'DrvBhvLatDecelThreshold' },
      { key: 'initialConfig.eventThresholds.drvBhvAltDecelThreshold', title: 'DrvBhvAltDecelThreshold' },
    ],
    [
      { key: 'initialConfig.eventThresholds.drvBhvAccelEventMaxDuration', title: 'DrvBhvAccelEventMaxDuration' },
      { key: 'initialConfig.eventThresholds.drvBhvAccelDiscret', title: 'DrvBhvAccelDiscret' },
      { key: 'initialConfig.eventThresholds.drvBhvCrashAccelThreshold', title: 'DrvBhvCrashAccelThreshold' },
      { key: 'initialConfig.eventThresholds.alarmImpactThreshold', title: 'AlarmImpactThreshold' },
      { key: 'initialConfig.eventThresholds.alarmTiltThreshold', title: 'AlarmTiltThreshold' },
      { key: 'initialConfig.eventThresholds.lowBatteryVoltageThreshold', title: 'LowBatteryVoltageThreshold' },
      {
        key: 'initialConfig.eventThresholds.lowBatteryVoltageCancelThreshold',
        title: 'LowBatteryVoltageCancelThreshold',
      },
      { key: 'initialConfig.remoteStartType', title: 'RemoteStartType' },
      { key: 'initialConfig.remoteStartIgnStarterInterval', title: 'RemoteStartIgnStarterInterval' },
      { key: 'initialConfig.tcuInstallationAngle', title: 'TcuInstallationAngle' },
    ],
  ],
};
