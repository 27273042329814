import { useParams, useNavigate } from 'react-router-dom';
import { useSuccess, ROLES_ENUM } from 'helpers';
import { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { dealerService, fleetService, plantsService, userService } from 'services';
import { USER_CREATE_ROLES_LIST, USER_FORM } from './helpers';
import { Select, Input, Button, ButtonTypes, ERRORS, updateFormErrors } from '@bs/techconnect-ui';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { ReactComponent as Back } from 'icons/chevron.svg';

import styles from './UserPage.module.css';

const UserPage = () => {
  const { userId } = useParams();
  const isCreate = userId === 'new';
  const navigate = useNavigate();
  const back = () => navigate('/users');
  const { setSuccess } = useSuccess();

  const [loading, setLoading] = useState(false);
  const [plants, setPlants] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [fleets, setFleets] = useState([]);
  const [exitModal, setExitModal] = useState(false);

  const {
    control,
    reset,
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setError,
    watch,
    trigger,
  } = useForm({ mode: 'onTouched' });

  const { getUser, createUser, updateUser } = userService;
  const fetchUser = useCallback(() => {
    setLoading(true);
    getUser(userId)
      .then(({ status, data }) => {
        if (status === 200) {
          const { name, surname, phone, roles, email, plant, dealer, fleet } = data || {};
          reset({
            name,
            surname,
            phone,
            role: roles,
            email,
            plantId: plant?.id,
            dealerId: dealer?.id,
            fleetId: fleet?.id,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [getUser, reset, userId]);
  useEffect(() => {
    if (!isCreate) fetchUser();
  }, [fetchUser, isCreate]);

  const role = watch('role');
  const isOEM = typeof role === 'string' && role.includes(ROLES_ENUM.oem);
  useEffect(() => {
    if (isOEM) {
      setLoading(true);
      plantsService
        .getPlants()
        .then(({ data, status }) => {
          if (status === 200 && Array.isArray(data?.items)) {
            setPlants(data.items.map(plant => ({ id: plant.id, title: plant.name })));
          }
        })
        .finally(() => setLoading(false));
    }
  }, [isOEM]);

  const isDealer = typeof role === 'string' && role.includes(ROLES_ENUM.dealer);
  useEffect(() => {
    if (isDealer) {
      setLoading(true);
      dealerService
        .getDealers()
        .then(({ data, status }) => {
          if (status === 200 && Array.isArray(data?.items)) {
            setDealers(data.items.map(dealer => ({ id: dealer.id, title: dealer.name })));
          }
        })
        .finally(() => setLoading(false));
    }
  }, [isDealer]);

  const isFleetAdmin = typeof role === 'string' && role.includes(ROLES_ENUM.FleetAdmin);
  useEffect(() => {
    if (isFleetAdmin) {
      setLoading(true);
      fleetService
        .getFleets()
        .then(({ data, status }) => {
          if (status === 200 && Array.isArray(data?.items)) {
            setFleets(data.items.map(fleet => ({ id: fleet.id, title: fleet.name })));
          }
        })
        .finally(() => setLoading(false));
    }
  }, [isFleetAdmin]);

  const submit = form => {
    if (!isValid) {
      trigger();
      return;
    }
    const method = isCreate ? createUser : updateUser;
    method(form, userId).then(({ data, status }) => {
      if (status !== 200) updateFormErrors(data, form, setError);
      if (status === 200) {
        setSuccess(isCreate ? 'Пользователь создан' : 'Изменения сохранены');
        back();
      }
    });
  };

  return (
    <div className={styles['wrapper']}>
      <header className={styles['header']}>
        <Button
          className={styles['back-btn']}
          variant={ButtonTypes.IR}
          onClick={isDirty ? () => setExitModal(true) : back}
        >
          <Back />
        </Button>

        <h3>{isCreate ? 'Создание' : 'Редактирование'} пользователя</h3>

        <Button className={styles['submit-btn']} disabled={loading} onClick={() => handleSubmit(submit)()}>
          {isCreate ? 'Создать пользователя' : 'Сохранить изменения'}
        </Button>
      </header>

      <div className={styles['form']}>
        <div className={styles['form-row']}>
          {USER_FORM.map(({ key, title, options }, index) => (
            <Input
              key={key}
              label={title}
              className={styles['form-field']}
              register={register(key, options)}
              error={errors[key]}
              disabled={loading || (key === 'email' && !isCreate)}
              autoFocus={!index}
            />
          ))}
        </div>
        <div className={styles['form-row']}>
          <Controller
            control={control}
            name="role"
            rules={{ required: ERRORS.REQUIRED('Роль') }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Select
                className={styles['form-field']}
                label="Роль"
                placeholder="Не выбрано"
                list={USER_CREATE_ROLES_LIST.map(r => ({ ...r, id: r.key }))}
                selected={value}
                onSelect={onChange}
                onBlur={onBlur}
                error={error}
                disabled={loading || !isCreate}
              />
            )}
          />
          {isOEM ? (
            <Controller
              control={control}
              name="plantId"
              rules={{ required: ERRORS.REQUIRED('Автопроизводитель') }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Select
                  className={styles['form-field']}
                  label="Автопроизводители"
                  placeholder="Не выбрано"
                  list={plants}
                  selected={value}
                  onSelect={onChange}
                  onBlur={onBlur}
                  error={error}
                  disabled={loading || !isCreate}
                />
              )}
            />
          ) : isDealer ? (
            <Controller
              control={control}
              name="dealerId"
              rules={{ required: ERRORS.REQUIRED('Дилер') }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Select
                  className={styles['form-field']}
                  label="Дилерские центры"
                  placeholder="Не выбрано"
                  list={dealers}
                  selected={value}
                  onSelect={onChange}
                  onBlur={onBlur}
                  error={error}
                  disabled={loading || !isCreate}
                />
              )}
            />
          ) : isFleetAdmin ? (
            <Controller
              control={control}
              name="fleetId"
              rules={{ required: ERRORS.REQUIRED('Автопарк') }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Select
                  className={styles['form-field']}
                  label="Автопарки"
                  placeholder="Не выбрано"
                  list={fleets}
                  selected={value}
                  onSelect={onChange}
                  onBlur={onBlur}
                  error={error}
                  disabled={loading}
                />
              )}
            />
          ) : (
            <div className={styles['form-field']} />
          )}
          <div className={styles['form-field']} />
          <div className={styles['form-field']} />
        </div>
      </div>

      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={back} />
    </div>
  );
};

export default UserPage;
