import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Controller, useForm } from 'react-hook-form';

import { accessGroupService } from 'services';
import {
  Button,
  ButtonGroup,
  ButtonTypes,
  ERRORS,
  FIELD_VALIDATION,
  Input,
  Paper,
  Select,
  updateFormErrors,
} from '@bs/techconnect-ui';
import { useError, useSuccess } from 'helpers';
import { AG_FORM_TABS, AG_TYPES, createMethod } from '../helpers';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { VehicleList } from './VehicleList/VehicleList';
import { UserList } from './UserList/UserList';
import { ReactComponent as Back } from 'icons/chevron.svg';

import styles from './AccessGroupForm.module.css';

const AccessGroupForm = () => {
  const { groupId } = useParams();
  const isCreate = groupId === 'new';
  const navigate = useNavigate();
  const { setSuccess } = useSuccess();
  const { setError } = useError();

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(AG_FORM_TABS.v);
  const [exitModal, setExitModal] = useState(false);
  const [vehiclesAGCount, setVehiclesAGCount] = useState(0);
  const [usersAGCount, setUsersAGCount] = useState(0);
  const [vehiclesAGCreate, setVehiclesAGCreate] = useState([]);
  const [usersAGCreate, setUsersAGCreate] = useState([]);

  const {
    control,
    register,
    formState: { errors, isDirty },
    reset,
    handleSubmit,
  } = useForm({ mode: 'onTouched' });

  const { getAccessGroup, updateAccessGroup, createAccessGroup, postVehiclesToAccessGroup, postUsersToAccessGroup } =
    accessGroupService;

  const fetchAG = useCallback(() => {
    setLoading(true);
    getAccessGroup(groupId)
      .then(({ data, status }) => {
        if (status === 200) reset(data);
      })
      .finally(() => setLoading(false));
  }, [getAccessGroup, groupId, reset]);

  useEffect(() => {
    if (!isCreate) fetchAG();
  }, [fetchAG, isCreate]);

  const submit = form => {
    const { name } = form;
    setLoading(true);
    const method = !isCreate ? updateAccessGroup({ name }, groupId) : createAccessGroup(form);
    method
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data, form, setError);
        if (status === 200) {
          if (!isCreate) {
            setSuccess('Изменения сохранены');
          } else {
            if (!vehiclesAGCreate.length && !usersAGCreate.length) {
              setSuccess('Группа доступа создана');
            } else {
              const promises = [];
              if (vehiclesAGCreate.length) {
                const vehiclesId = vehiclesAGCreate.map(({ vehicleId }) => vehicleId);
                promises.push(postVehiclesToAccessGroup(data?.id, { vehicles: vehiclesId }));
              }
              if (usersAGCreate.length) {
                const usersId = usersAGCreate.map(({ id }) => id);
                promises.push(postUsersToAccessGroup(data?.id, { users: usersId }));
              }
              Promise.all(promises).then(results => {
                if (results.every(result => result.status === 204)) {
                  setSuccess('Группа доступа создана');
                }
              });
            }
          }
          navigate(`/accessGroups`);
        }
      })
      .finally(() => setLoading(false));
  };

  const close = () => {
    if (isDirty || vehiclesAGCreate.length || usersAGCreate.length) {
      setExitModal(true);
    } else {
      navigate('/accessGroups');
      reset({});
    }
  };

  return (
    <>
      <Paper className={styles['header']}>
        <Button className={styles['back-btn']} variant={ButtonTypes.IR} onClick={close}>
          <Back />
        </Button>
        <h3>{isCreate ? 'Создание ' : 'Редактирование'} группы доступа</h3>
        <Button className={styles['submit-btn']} onClick={handleSubmit(submit)}>
          {isCreate ? `Создать группу` : 'Сохранить изменения'}
        </Button>
      </Paper>

      <div className={styles['body']}>
        <div className={styles['params-wrapper']}>
          <Input
            label="Название"
            register={register('name', {
              required: ERRORS.REQUIRED('Название'),
              validate: v => FIELD_VALIDATION.DURATION(v, 1, 130),
            })}
            disabled={loading}
            error={errors.name}
            className={styles['params-field']}
          />
          <Controller
            control={control}
            name="rights"
            rules={{ required: ERRORS.REQUIRED('Тип доступа') }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Select
                className={styles['params-field']}
                label="Тип доступа"
                placeholder="Не выбрано"
                list={Object.keys(AG_TYPES).map(id => ({ id, title: AG_TYPES[id] }))}
                selected={value}
                onSelect={onChange}
                onBlur={onBlur}
                error={error}
                disabled={!isCreate || loading}
              />
            )}
          />
        </div>
        <div className={styles['tabs']}>
          <ButtonGroup
            buttons={[
              { key: AG_FORM_TABS.v, title: `Автомобили (${!isCreate ? vehiclesAGCount : vehiclesAGCreate.length})` },
              { key: AG_FORM_TABS.u, title: `Пользователи (${!isCreate ? usersAGCount : usersAGCreate.length})` },
            ]}
            selected={[tab]}
            onSelect={setTab}
            disabled={loading}
            variant="underline"
          />
        </div>
        <VehicleList
          open={tab === AG_FORM_TABS.v}
          disabled={loading}
          accessGroup={!isCreate && groupId}
          vehiclesAGCount={vehiclesAGCount}
          setVehiclesAGCount={setVehiclesAGCount}
          createMethod={createMethod(vehiclesAGCreate, setVehiclesAGCreate, setError, isCreate)}
        />
        <UserList
          open={tab === AG_FORM_TABS.u}
          disabled={loading}
          accessGroup={!isCreate && groupId}
          usersAGCount={usersAGCount}
          setUsersAGCount={setUsersAGCount}
          createMethod={createMethod(usersAGCreate, setUsersAGCreate, setError, isCreate)}
        />
      </div>
      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={() => navigate(`/accessGroups/`)} />
    </>
  );
};

export default AccessGroupForm;
