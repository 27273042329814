import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import { optionService, optionValuesService } from 'services';
import { useError, useSuccess } from 'helpers';
import { OPTION_TYPES, OPTION_DEFAULT } from 'components/Options/helpers';
import { Modal, Select, Input, Button, ButtonTypes, ERRORS, updateFormErrors, Radio } from '@bs/techconnect-ui';
import { ReactComponent as Close } from 'icons/cross.svg';

import styles from '../BrandsModels.module.css';

export const OptionsValuesForm = ({
  itemId = '',
  optionValue = {},
  open = false,
  setOpen = () => {},
  updateList = () => {},
  createMethod = () => {},
  isCreateEntity = false,
  preparedOptions = [],
  setPreparedOptions = () => {},
}) => {
  const { updateOptionValue } = optionValuesService;
  const { setSuccess } = useSuccess();
  const { setError: errorPopper } = useError();
  const [loading, setLoading] = useState(false);
  const {
    register,
    reset,
    formState: { isSubmitting, isValid },
    handleSubmit,
    setError,
    watch,
    control,
  } = useForm({ mode: 'onTouched' });
  const id = watch('id');
  const optionId = watch('optionId');

  useEffect(() => {
    const { id, value: v, option = {} } = optionValue;
    const newValue = option.type === OPTION_TYPES.B ? v === 'true' : v;
    const optionId = isCreateEntity ? id : option?.id;
    reset({ id, optionId, value: newValue });
  }, [optionValue, reset, isCreateEntity]);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (open) {
      setLoading(true);
      //TODO: Сделать что то с пейджингом
      optionService
        .getOptions({ page: 1, pageSize: 100 })
        .then(({ status, data }) => {
          if (status === 200 && Array.isArray(data?.items)) {
            setOptions(data.items);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [open]);

  const close = () => {
    reset({});
    setOpen();
  };
  const submit = form => {
    if (isCreateEntity && !Object.keys(optionValue).length) {
      const selectOption = options.find(option => option.id === form.optionId);
      if (preparedOptions.some(option => option.id === optionId)) {
        errorPopper('Данная опция уже есть в списке');
      } else {
        setPreparedOptions(prev => [{ ...selectOption, value: form?.value.toString() }, ...prev]);
        setSuccess('Опция добавлена');
        close();
      }
    } else if (isCreateEntity && Object.keys(optionValue).length) {
      setPreparedOptions(prev =>
        prev.map(item => {
          if (item.id === optionValue.id) {
            return { ...item, value: form.value?.toString() };
          }
          return item;
        }),
      );
      setSuccess('Изменения сохранены');
      close();
    } else {
      setLoading(true);
      const method = id ? updateOptionValue : createMethod;
      method(form, id ? id : itemId)
        .then(({ status, data }) => {
          if (status === 200) {
            setSuccess(`${id ? 'Изменения сохранены' : 'Опция добавлена'}`);
            updateList();
            close();
          }
          if (status !== 200) updateFormErrors(data, form, setError);
        })
        .finally(() => setLoading(false));
    }
  };

  const getOptionValueInput = () => {
    const { id: oId, type, allowedValues } = options?.find(o => o.id === optionId) || {};
    if (!oId) return null;

    const { B, L, S } = OPTION_TYPES;

    if (type === B) {
      return (
        <Controller
          control={control}
          name="value"
          render={({ field: { onChange, value } }) => (
            <>
              <Radio
                className={[styles['value-form-field'], styles['value-form-field-radio']].join(' ')}
                label="False"
                id="boolean-value"
                checked={!value}
                onClick={() => onChange(!value)}
              />
              <Radio
                className={[styles['value-form-field'], styles['value-form-field-radio']].join(' ')}
                label="True"
                id="boolean-value"
                checked={value}
                onClick={() => onChange(!value)}
              />
            </>
          )}
        />
      );
    }

    if (type === L) {
      return (
        <Controller
          control={control}
          name="value"
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <Select
              className={styles['value-form-field']}
              titleKey="id"
              label="Значение"
              disabled={loading}
              list={allowedValues.map(id => ({ id }))}
              selected={value}
              onSelect={onChange}
              onBlur={onBlur}
              error={error}
            />
          )}
        />
      );
    }

    return (
      <Input
        className={styles['value-form-field']}
        disabled={loading}
        label="Значение"
        type={type === S ? 'text' : 'number'}
        register={register('value')}
      />
    );
  };

  return (
    <Modal open={open} className={styles['value-form-wrapper']}>
      <Button variant={ButtonTypes.T} className={styles['value-form-close']} onClick={close}>
        <Close />
      </Button>

      <header>{optionValue?.id ? 'Редактирование' : 'Создание'} значения опции</header>
      <Controller
        control={control}
        name="optionId"
        rules={{ required: ERRORS.REQUIRED('Опция') }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <Select
            style={{ zIndex: 100 }}
            className={styles['value-form-field']}
            label="Опция"
            list={options}
            selected={value}
            onSelect={(id, item) => reset({ optionId: id, value: OPTION_DEFAULT[item?.type] })}
            onBlur={onBlur}
            error={error}
            disabled={loading || optionValue?.id}
            titleKey="name"
          />
        )}
      />

      {getOptionValueInput()}

      <footer>
        <Button onClick={() => handleSubmit(submit)()} disabled={isSubmitting || !isValid}>
          {optionValue?.id ? 'Сохранить' : 'Создать'}
        </Button>
      </footer>
    </Modal>
  );
};

OptionsValuesForm.propTypes = {
  optionValue: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  open: PropTypes.bool,
  createMethod: PropTypes.func,
  setOpen: PropTypes.func,
  updateList: PropTypes.func,
  itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  isCreateEntity: PropTypes.bool,
  preparedOptions: PropTypes.array,
  setPreparedOptions: PropTypes.func,
};
