import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { BRAND_MODELS_LIST } from './helpers';
import { modelBrandService, plantsService } from 'services';
import {
  Table,
  Checkbox,
  Input,
  InputTypes,
  Button,
  ERRORS,
  FIELD_VALIDATION,
  updateFormErrors,
} from '@bs/techconnect-ui';

import styles from './Plants.module.css';

export const PlantsForm = ({ plant = {}, open = false, setOpen = () => {}, updateList = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState([]);
  const [checkedModels, setCheckedModels] = useState([]);
  const {
    reset,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: 'onTouched' });

  useEffect(() => {
    modelBrandService.getModels().then(({ status, data }) => {
      if (status === 200) {
        setModels(data.items);
      }
    });
  }, [plant]);

  useEffect(() => {
    if (plant.id) {
      plantsService.getPlantsById(plant.id).then(({ status, data }) => {
        if (status === 200) {
          reset(data);
          setCheckedModels(data.models.map(model => model.id));
        }
      });
    } else {
      reset({});
      setCheckedModels([]);
    }
  }, [plant, reset]);

  const close = () => {
    reset({});
    setCheckedModels([]);
    setOpen();
  };
  const create = form => {
    const { id } = form;
    const body = { ...form, models: checkedModels };
    const method = id ? plantsService.updatePlants : plantsService.createPlants;
    setLoading(true);
    method(body, id)
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data, form, setError);
        if (status === 200) {
          updateList();
          close();
        }
      })
      .finally(() => setLoading(false));
  };

  const isCheckedModel = id => checkedModels.includes(id);

  const handleChange = (id, isChecked) => {
    if (isChecked) {
      setCheckedModels(prevCheckedModels => [...prevCheckedModels, id]);
    } else {
      setCheckedModels(prevCheckedModels => prevCheckedModels.filter(modelId => modelId !== id));
    }
  };
  const handler = row => ({
    ...row,
    name: `${row.brand?.name} ${row.name}`,
    checkBox: (
      <Checkbox
        key={row.id}
        id={`${row.id}`}
        className={styles['create-field']}
        checked={isCheckedModel(row.id)}
        onChange={e => {
          handleChange(row.id, e.target.checked);
        }}
        disabled={loading}
      />
    ),
  });

  if (!open) return <article className={styles['form-wrapper']} />;
  return (
    <article className={styles['form-wrapper']}>
      <header>
        {plant?.id ? 'Редактирование' : 'Создание'} автопроизводителя
        <Button onClick={() => handleSubmit(create)()}>{plant.id ? 'Редактировать' : 'Создать'}</Button>
      </header>
      <Input
        variant={InputTypes.L}
        label="Название"
        register={register('name', {
          required: ERRORS.REQUIRED('Название'),
          validate: v => FIELD_VALIDATION.DURATION(v, 1, 30),
        })}
        error={errors.name}
        disabled={loading}
        className={styles['input-field']}
      />
      <hr />
      <h3>список доступных моделей</h3>
      <div className={styles['models-box']}>
        <Table
          idKey="id"
          className={styles['models-checkbox-table']}
          columns={BRAND_MODELS_LIST}
          handler={handler}
          rows={models}
        />
      </div>
    </article>
  );
};

PlantsForm.propTypes = {
  plant: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  updateList: PropTypes.func,
};
