import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { accessGroupService, userService } from 'services';
import { Table, Input, Button, ButtonTypes, parseFIO, FIELD_VALIDATION } from '@bs/techconnect-ui';
import { USER_COLUMNS } from 'components/AccessGroups/helpers';
import { ReactComponent as Search } from 'icons/search.svg';
import { ReactComponent as Clear } from 'icons/cross.svg';
import { ReactComponent as Add } from 'icons/plus-s.svg';
import { ReactComponent as Remove } from 'icons/minus.svg';

import styles from '../../AccessGroupForm/AccessGroupForm.module.css';

export const UserList = ({
  open = false,
  disabled = false,
  accessGroup = '',
  usersAGCount = 0,
  setUsersAGCount = () => {},
  createMethod = () => {},
}) => {
  const [search, setSearch] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  //ALL USERS
  const [users, setUsers] = useState([]);
  const [usersPage, setUsersPage] = useState(1);
  const [usersTotalPages, setUsersTotalPages] = useState(1);
  const [usersCount, setUsersCount] = useState(0);
  //AG USERS
  const [usersAG, setUsersAG] = useState([]);
  const [usersAGPage, setUsersAGPage] = useState(1);
  const [usersAGTotalPages, setUsersAGTotalPages] = useState(1);
  const [usersAGPageList, setUsersAGPageList] = useState([]);

  const fetchUsers = useCallback(() => {
    userService.getUsers({ page: usersPage, pageSize: 8, search }).then(({ status, data }) => {
      if (status === 200 && Array.isArray(data?.items)) setUsers(data.items);
      if (!isNaN(data.totalPages)) setUsersTotalPages(data.totalPages);
      if (!isNaN(data.count)) setUsersCount(data.count);
    });
  }, [search, usersPage]);

  const fetchUsersAG = useCallback(() => {
    accessGroupService
      .getAccessGroupUsers(accessGroup, { page: usersAGPage, pageSize: 8, search })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) setUsersAG(data.items.map(v => ({ ...v, ag: true })));
        if (!isNaN(data.totalPages)) setUsersAGTotalPages(data.totalPages);
        if (!isNaN(data.count)) setUsersAGCount(data.count);
      });
  }, [accessGroup, search, setUsersAGCount, usersAGPage]);

  useEffect(() => {
    fetchUsers();
  }, [accessGroup, fetchUsers, usersPage]);

  useEffect(() => {
    if (accessGroup) fetchUsersAG();
  }, [accessGroup, usersAGPage, fetchUsersAG]);

  const apply = () => {
    setUsersPage(1);
    setSearch(searchInput);
  };

  const clear = () => {
    setSearchInput('');
    setSearch('');
  };

  const handleUser = ({ id, ag }) => {
    setLoading(true);
    const method = ag ? accessGroupService.deleteUsersFromAccessGroup : accessGroupService.postUsersToAccessGroup;
    method(accessGroup, { users: [id] })
      .then(({ status }) => {
        if (status === 204) fetchUsersAG();
      })
      .finally(() => setLoading(false));
  };

  const handler = row => ({
    ...row,
    action: (
      <Button
        variant={ButtonTypes.T}
        onClick={accessGroup ? () => handleUser(row) : () => createHandler(row)}
        disabled={loading || disabled}
      >
        {row.ag || row.agCreate ? <Remove /> : <Add />}
      </Button>
    ),
    fio: parseFIO(row),
  });

  const { items, createHandler, usePageHandler } = createMethod;
  usePageHandler(items, usersAGTotalPages, setUsersAGTotalPages, setUsersAGPageList, usersAGPage, setUsersAGPage);

  if (!open) return null;

  const filterHandler = (value, validate) => {
    setSearchInput(value);
    setTouched(true);
    const message = validate && validate(value, searchInput);
    setError(typeof message === 'string' ? message : false);
  };
  const errorMessage = () => (touched && error ? { message: error } : undefined);

  return (
    <>
      <div className={styles['filters']}>
        <Input
          label="ФИО / Телефон / E-mail"
          className={styles['filters-user']}
          value={searchInput}
          onChange={({ target }) => filterHandler(target.value, value => FIELD_VALIDATION.DURATION(value, 3, 100))}
          onEnter={apply}
          error={errorMessage()}
        />
        <Button disabled={disabled || loading} variant={ButtonTypes.I} onClick={apply}>
          <Search />
        </Button>

        <Button variant={ButtonTypes.T} onClick={clear} disabled={!search || disabled || loading}>
          Сбросить фильтр&nbsp;
          <Clear />
        </Button>
      </div>

      <div className={styles['list']}>
        <div className={styles['list-column']}>
          <header>Все пользователи ({usersCount})</header>
          <Table
            className={styles['table']}
            columns={USER_COLUMNS}
            loading={loading}
            rows={users}
            totalPages={usersTotalPages}
            setPage={setUsersPage}
            page={usersPage}
            idKey="id"
            handler={handler}
          />
        </div>

        <div className={styles['list-column']}>
          <header>В группе ({accessGroup ? usersAGCount : items.length})</header>
          <Table
            className={styles['table']}
            columns={USER_COLUMNS}
            loading={loading}
            rows={accessGroup ? usersAG : usersAGPageList}
            totalPages={usersAGTotalPages}
            setPage={setUsersAGPage}
            page={usersAGPage}
            idKey="id"
            handler={handler}
          />
        </div>
      </div>
    </>
  );
};

UserList.propTypes = {
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  accessGroup: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  usersAGCount: PropTypes.number,
  setUsersAGCount: PropTypes.func,
  createMethod: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};
