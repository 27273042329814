import { ERRORS, FIELD_VALIDATION } from '@bs/techconnect-ui';

export const COLUMNS = [
  { key: 'optionName', title: 'Название' },
  { key: 'optionKey', title: 'Ключ' },
  { key: 'optionType', title: 'Тип значения' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const OPTION_TYPES = {
  B: 'Boolean',
  F: 'Float',
  S: 'String',
  I: 'Integer',
  L: 'List',
};
export const OPTION_DEFAULT = {
  [OPTION_TYPES.B]: false,
  [OPTION_TYPES.F]: 0,
  [OPTION_TYPES.S]: '',
  [OPTION_TYPES.I]: 0,
  [OPTION_TYPES.L]: '',
};

export const OPTION_TYPE_LIST = Object.values(OPTION_TYPES).map(id => ({ id, title: id }));

export const OPTION_FORM = [
  {
    key: 'key',
    title: 'Название',
    options: { required: ERRORS.REQUIRED('Название'), validate: v => FIELD_VALIDATION.DURATION(v, 1, 200) },
  },
  {
    key: 'name',
    title: 'Ключ',
    options: { required: ERRORS.REQUIRED('Ключ'), validate: v => FIELD_VALIDATION.DURATION(v, 1, 200) },
  },
  {
    key: 'type',
    title: 'Тип значения',
    options: { required: ERRORS.REQUIRED('Тип значения') },
    list: OPTION_TYPE_LIST,
  },
];
