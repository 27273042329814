import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { optionValuesService, vehicleService } from 'services';
import { OPTIONS_VALUES_COLUMNS } from '../helpers';
import { OptionsValuesForm } from 'components/BrandsModels/OptionsValuesForm/OptionsValuesForm';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { Button, ButtonTypes, Table } from '@bs/techconnect-ui';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';

import styles from '../VehiclePage.module.css';

export const VehicleOptions = ({
  display = 'none',
  vehicleId = 'new',
  optionValueForm = false,
  setOptionValueForm = () => {},
  optionsValues = [],
  setOptionsValues = () => {},
}) => {
  const isCreate = vehicleId === 'new';
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const handlerOptionsValues = row => ({
    ...row,
    name: row?.option?.name || row?.name,
    edit: (
      <Button variant={ButtonTypes.IT} onClick={() => setOptionValueForm(row)}>
        <Edit />
      </Button>
    ),
    delete: (
      <Button variant={ButtonTypes.IT} onClick={() => setDeleteModal(row.id)}>
        <Delete />
      </Button>
    ),
  });

  const fetchOptionsValues = useCallback(() => {
    setLoading(true);
    vehicleService
      .getVehicleOptionValues(vehicleId)
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setOptionsValues(data?.items);
        }
      })
      .finally(() => setLoading(false));
  }, [setOptionsValues, vehicleId]);
  useEffect(() => {
    if (vehicleId && !isCreate) fetchOptionsValues();
  }, [vehicleId, fetchOptionsValues, isCreate]);

  const deleteOptionHandler = id => {
    setOptionsValues(prev => prev.filter(option => option.id !== id));
    setDeleteModal(false);
  };

  return (
    <div style={{ display }} className={styles['options']}>
      <Table
        idKey="id"
        className={styles['options-table']}
        columns={OPTIONS_VALUES_COLUMNS}
        handler={handlerOptionsValues}
        loading={loading}
        rows={optionsValues}
      />
      <OptionsValuesForm
        itemId={isCreate ? false : vehicleId}
        optionValue={optionValueForm}
        open={!!optionValueForm}
        setOpen={() => setOptionValueForm(false)}
        createMethod={vehicleService.createVehicleOptionValue}
        updateList={fetchOptionsValues}
        isCreateEntity={isCreate}
        preparedOptions={optionsValues}
        setPreparedOptions={setOptionsValues}
      />

      <ConfirmModal
        open={!!deleteModal}
        setOpen={() => setDeleteModal(!deleteModal)}
        updateList={isCreate ? () => {} : fetchOptionsValues}
        method={() => optionValuesService.deleteOptionValue(deleteModal)}
        actionHandler={isCreate ? () => deleteOptionHandler(deleteModal) : false}
        header="Удалить значение опции?"
        description="Я понимаю, что восстановить значение опции будет невозможно"
      />
    </div>
  );
};

VehicleOptions.propTypes = {
  display: PropTypes.oneOf(['none', 'flex']),
  vehicleId: PropTypes.string,
  optionValueForm: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setOptionValueForm: PropTypes.func,
  optionsValues: PropTypes.array,
  setOptionsValues: PropTypes.func,
};
