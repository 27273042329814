import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { modelBrandService } from 'services';
import { Select, Input, SelectDate, ERRORS, FIELD_VALIDATION } from '@bs/techconnect-ui';

import styles from '../VehiclePage.module.css';

export const VehicleForm = ({ display = 'none', control = '', register = () => {}, errors = {}, loading = false }) => {
  const [loadingModels, setLoadingModels] = useState(false);
  const [models, setModels] = useState([]);
  useEffect(() => {
    setLoadingModels(true);
    modelBrandService
      .getModels()
      .then(({ data, status }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setModels(data.items.map(m => ({ ...m, title: [m?.brand?.name, m?.name].join(' ') })));
        }
      })
      .finally(() => setLoadingModels(false));
  }, []);

  return (
    <div style={{ display }} className={styles['params']}>
      <Controller
        control={control}
        name="modelId"
        rules={{ required: ERRORS.REQUIRED('Модель') }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <Select
            className={styles['params-field']}
            label="Модель"
            placeholder="Не выбрано"
            list={models}
            selected={value}
            onSelect={onChange}
            onBlur={onBlur}
            error={error}
            disabled={loadingModels || loading}
          />
        )}
      />
      <Input
        label="VIN"
        className={styles['params-field']}
        register={register('vin', {
          required: ERRORS.REQUIRED('VIN'),
          validate: v => FIELD_VALIDATION.LENGTH(v, 17),
        })}
        error={errors.vin}
        disabled={loading}
      />
      <Input
        label="Гос. номер"
        className={styles['params-field']}
        register={register('plateNumber', {
          validate: v => FIELD_VALIDATION.DURATION(v, 0, 10),
        })}
        error={errors.plateNumber}
        disabled={loading}
      />

      <Controller
        control={control}
        name="saleDate"
        render={({ field: { onChange, value } }) => (
          <SelectDate
            label="Дата продажи"
            className={[styles['params-field'], styles['params-field-date']].join(' ')}
            date={value}
            setDate={onChange}
            disabled={loading}
          />
        )}
      />
    </div>
  );
};

VehicleForm.propTypes = {
  display: PropTypes.oneOf(['none', 'flex']),
  control: PropTypes.object,
  register: PropTypes.func,
  errors: PropTypes.object,
  loading: PropTypes.bool,
};
