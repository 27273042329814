import { useState } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { DEVICE_PARAMS } from '../helpers';
import { Shorter, Button, ButtonTypes, Modal } from '@bs/techconnect-ui';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { DeviceSelect } from './DeviceSelect/DeviceSelect';
import { ReactComponent as Close } from 'icons/cross.svg';

import styles from './VehicleDevice.module.css';

export const VehicleDevice = ({
  display = 'none',
  vehicleId = '',
  control = {},
  data = { deviceData: {} },
  update = () => {},
  fetchVehicle = () => {},
  removeDevice = () => {},
}) => {
  const isCreate = vehicleId === 'new';
  const { deviceData = {} } = data;
  const { deviceId, ...other } = deviceData;
  const [deviceModal, setDeviceModal] = useState(false);
  const [removeDeviceModal, setRemoveDeviceModal] = useState(false);

  if (isCreate) {
    return (
      <div style={{ display }} className={styles['device-actions']}>
        <Controller
          control={control}
          name="deviceId"
          render={({ field: { onChange, value } }) => <DeviceSelect selected={value} setSelected={onChange} />}
        />
      </div>
    );
  }
  return (
    <>
      <div style={{ display }} className={styles['device-params']}>
        {Object.keys(DEVICE_PARAMS).map(key => (
          <div className={styles['device-field']} key={key}>
            <div className={styles['device-title']}>{DEVICE_PARAMS[key]}</div>
            <Shorter length={120}>{other[key]}</Shorter>
          </div>
        ))}
      </div>
      <div style={{ display }} className={styles['device-actions']}>
        <Button variant={ButtonTypes.S} className={styles['device-action']} onClick={() => setDeviceModal(true)}>
          {deviceId ? 'Сменить' : 'Привязать'} устройство
        </Button>
        {deviceId && (
          <Button
            variant={ButtonTypes.S}
            className={styles['device-action']}
            onClick={() => setRemoveDeviceModal(true)}
          >
            Отвязать устройство
          </Button>
        )}

        <Modal open={deviceModal} className={styles['devices-modal']}>
          <Button variant={ButtonTypes.T} className={styles['close']} onClick={() => setDeviceModal(!deviceModal)}>
            <Close />
          </Button>

          <header>Выберите устройство</header>

          <Controller
            control={control}
            name="deviceId"
            render={({ field: { onChange, value } }) => (
              <>
                <DeviceSelect selected={value} setSelected={onChange} />

                <Button className={styles['devices-action']} disabled={!value} onClick={update}>
                  Применить
                </Button>
              </>
            )}
          />
        </Modal>

        <ConfirmModal
          open={!!removeDeviceModal}
          setOpen={() => setRemoveDeviceModal(!removeDeviceModal)}
          updateList={fetchVehicle}
          method={removeDevice}
          header="Отвязывать устройство?"
          description="Я понимаю, что отвязываю устройство от транспортного средства"
          submit="Отвязать"
        />
      </div>
    </>
  );
};

VehicleDevice.propTypes = {
  display: PropTypes.oneOf(['none', 'flex']),
  vehicleId: PropTypes.string,
  control: PropTypes.object,
  data: PropTypes.object,
  update: PropTypes.func,
  fetchVehicle: PropTypes.func,
  removeDevice: PropTypes.func,
};
