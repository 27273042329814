import { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useNavigate, NavLink } from 'react-router-dom';

import { userService } from 'services';
import { ROLES, ROLES_ENUM } from 'helpers';
import { COLUMNS, FILTERS } from './helpers';
import { Popper, Table, Button, ButtonTypes, ListFilters, pageSize } from '@bs/techconnect-ui';

import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';

import styles from './Users.module.css';

const Users = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);

  const fetchUsers = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem(`usersFilters`) || '{}') || {};
    const { filter = false, ...queryFilter } = filterData;
    setLoading(true);
    userService
      .getUsers({ page, pageSize, ...(filter && queryFilter) })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(
            data.items.map((user = {}) => {
              const { name: dealer } = user?.dealer || {};
              const { name: plant } = user?.plant || {};

              return { ...user, dealer, plant };
            }),
          );
          if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [page, filters]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, filters]);

  const getRoles = roles => {
    if (typeof roles === 'string') {
      const rolesArr = roles.split(',');
      return rolesArr.map(role => ROLES[role]).join(', ');
    }
  };
  const getFleet = ({ roles, fleet }) => {
    if (typeof roles === 'string' && roles.includes(ROLES_ENUM.FleetAdmin)) {
      return fleet?.name;
    } else {
      return ' ';
    }
  };
  const handler = row => ({
    ...row,
    roles: getRoles(row.roles),
    fleet: getFleet(row),
    edit: (
      <Popper popup={'Редактировать'}>
        <NavLink to={`/users/${row.id}`}>
          <Button variant={ButtonTypes.IT}>
            <Edit />
          </Button>
        </NavLink>
      </Popper>
    ),
    delete: (
      <Popper popup={'Удалить'}>
        <Button variant={ButtonTypes.IT} onClick={() => setDeleteModal(row.id)}>
          <Delete />
        </Button>
      </Popper>
    ),
  });

  return (
    <>
      <ListFilters
        title="Пользователи"
        fields={FILTERS}
        filters={filters}
        setFilters={setFilters}
        createHandler={() => navigate('/users/new')}
        updateList={fetchUsers}
        inputStyles={styles['multiselect-roles']}
      />

      <Table
        className={styles['table']}
        columns={COLUMNS}
        rows={rows}
        totalPages={totalPages}
        loading={loading}
        idKey="id"
        handler={handler}
      />

      <ConfirmModal
        open={deleteModal}
        setOpen={() => setDeleteModal(!deleteModal)}
        updateList={fetchUsers}
        method={() => userService.deleteUser(deleteModal)}
        header="Удалить пользователя?"
        description="Я понимаю, что восстановить пользователя будет невозможно"
      />
    </>
  );
};

export default Users;
