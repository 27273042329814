import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import { useUser, useError, useSuccess } from 'helpers';
import { Error, Paper, Popup, PopupTypes } from '@bs/techconnect-ui';
import Vehicles from 'components/Vehicles/Vehicles';
import VehiclePage from 'components/VehiclePage/VehiclePage';
import Devices from 'components/Devices/Devices';
import Users from 'components/Users/Users';
import AccessGroups from 'components/AccessGroups/AccessGroups';
import Options from 'components/Options/Options';
import BrandsModels from 'components/BrandsModels/BrandsModels';
import Plants from 'components/Plants/Plants';
import IotDevices from 'components/IotDevices/IotDevices';
import { IotDevice } from 'components/IotDevices/IotDevice/IotDevice';
import Retranslations from 'components/Retranslations/Retranslations';
import RetranslationPage from 'components/RetranslationPage/RetranslationPage';
import BrandsModelsForm from 'components/BrandsModels/BrandsModelsForm/BrandsModelsForm';
import DeviceForm from 'components/Devices/DeviceForm/DeviceForm';
import UserPage from 'components/UserPage/UserPage';
import OptionsForm from 'components/Options/OptionsForm/OptionsForm';
import AccessGroupForm from 'components/AccessGroups/AccessGroupForm/AccessGroupForm';
import Fleets from 'components/Fleets/Fleets';
import FleetPage from 'components/FleetPage/FleetPage';

import styles from './Body.module.css';

const Body = () => {
  //POPUP MESSAGE
  const { error, setError } = useError();
  const { success, setSuccess } = useSuccess();
  const [popup, setMessage] = useState({ message: '' });
  useEffect(() => {
    if (error) {
      setMessage({ message: error, success: PopupTypes.E });
      setTimeout(() => setMessage(''), 6 * 1000);
    }
    return () => {
      setError('');
    };
  }, [error, setError]);

  useEffect(() => {
    if (success) {
      setMessage({ message: success, variant: PopupTypes.S });
      setTimeout(() => setMessage(''), 6 * 1000);
    }
    return () => {
      setSuccess('');
    };
  }, [success, setSuccess]);

  //CHECK ACCESS
  const {
    user: { roles = [] },
  } = useUser();
  if (!(roles.indexOf('admins') + 1)) return <Error code={403} title="Доступ запрещен" />;

  return (
    <Paper className={styles['wrapper']}>
      <Routes>
        <Route path="/" element={<Vehicles />} />
        <Route path="/vehicles" element={<Vehicles />} />
        <Route path="/vehicles/:vehicleId" element={<VehiclePage />} />
        <Route path="/devices" element={<Devices />} />
        <Route path="/devices/:deviceId" element={<DeviceForm />} />
        <Route path="/options" element={<Options />} />
        <Route path="/options/:optionId" element={<OptionsForm />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:userId" element={<UserPage />} />
        <Route path="/accessGroups" element={<AccessGroups />} />
        <Route path="/accessGroups/:groupId" element={<AccessGroupForm />} />
        <Route path="/brandsModels" element={<BrandsModels />} />
        <Route path="/brandsModels/brand/:id" element={<BrandsModelsForm />} />
        <Route path="/brandsModels/model/:id" element={<BrandsModelsForm />} />
        <Route path="/brandsModels/brand/:id/newModel" element={<BrandsModelsForm />} />
        <Route path="/plants" element={<Plants />} />
        <Route path="/iotDevices" element={<IotDevices />} />
        <Route path="/iotDevices/:deviceId" element={<IotDevice />} />
        <Route path="/retranslations" element={<Retranslations />} />
        <Route path="/retranslations/:retranslationId" element={<RetranslationPage />} />
        <Route path="/fleets" element={<Fleets />} />
        <Route path="/fleets/:fleetId" element={<FleetPage />} />

        <Route path="*" element={<Error />} />
      </Routes>

      <Popup {...popup} />
    </Paper>
  );
};

export default Body;
