import { parseDate } from '@bs/techconnect-ui';
import { EMPTY } from 'helpers';

export const processVehicle = v => {
  return {
    ...v,
    deviceData: {
      deviceId: v?.device?.deviceId,
      esn: v?.device?.esn || EMPTY,
      lastActivity: parseDate(v?.state?.lastActivity) || EMPTY,
      status: v?.device?.status || EMPTY,
      email: v?.owner?.email || EMPTY,
    },
  };
};

export const DEVICE_PARAMS = {
  esn: 'ESN',
  lastActivity: 'Последняя активность',
  status: 'Статус Provisioning',
  email: 'E-mail владельца',
};
export const DEVICE_FILTERS = {
  deviceId: 'DeviceID',
  esn: 'ESN',
  vehicleId: 'VehicleID',
};
export const DEVICE_COLUMNS = [
  { key: 'action', title: '' },
  { key: 'id', title: 'DeviceID' },
  { key: 'esn', title: 'ESN' },
  { key: 'vehicleId', title: 'VehicleID' },
];

export const OPTIONS_VALUES_COLUMNS = [
  { key: 'name', title: 'Название' },
  { key: 'value', title: 'Значение' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];
