import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { deviceService } from 'services';
import { DEVICE_FILTERS, DEVICE_COLUMNS } from '../../helpers';
import { Button, ButtonTypes, Radio, Table, Input, shortString } from '@bs/techconnect-ui';
import { ReactComponent as Close } from 'icons/cross.svg';
import { ReactComponent as Search } from 'icons/search.svg';

import styles from '../VehicleDevice.module.css';

export const DeviceSelect = ({ selected = '', setSelected = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [filtersInput, setFiltersInput] = useState({});
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setLoading(true);
    deviceService
      .getDevices({ page, pageSize: 7, ...filters })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) setRows(data.items);
        if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
      })
      .finally(() => setLoading(false));
  }, [page, filters]);

  const handler = row => ({
    ...row,
    action: (
      <Radio
        name="device"
        id={row.deviceId}
        value={row.deviceId}
        checked={selected === row.deviceId}
        onClick={() => setSelected(selected === row.deviceId ? '' : row.deviceId)}
      />
    ),
    id: row.deviceId,
    esn: row.esn,
    vehicleId: shortString(row.vehicle?.vehicleId),
    status: row.status,
  });

  const apply = () => {
    setFilters(filtersInput);
  };
  const clear = () => {
    setFiltersInput({});
    setFilters({});
  };

  return (
    <div className={styles['devices']}>
      <div className={styles['devices-filters']}>
        {Object.keys(DEVICE_FILTERS).map(key => (
          <Input
            key={key}
            className={styles['devices-filter']}
            label={DEVICE_FILTERS[key]}
            value={filtersInput[key] || ''}
            onChange={({ target }) => setFiltersInput({ ...filtersInput, [key]: target.value })}
          />
        ))}
        <Button variant={ButtonTypes.I} onClick={apply}>
          <Search />
        </Button>
      </div>

      <Button
        variant={ButtonTypes.T}
        className={styles['devices-clear-filters']}
        onClick={clear}
        disabled={!Object.keys(filters).length}
      >
        Сбросить фильтр&nbsp;
        <Close />
      </Button>

      <Table
        className={styles['devices-table']}
        columns={DEVICE_COLUMNS}
        loading={loading}
        rows={rows}
        totalPages={totalPages}
        setPage={setPage}
        page={page}
        idKey="deviceId"
        handler={handler}
      />
    </div>
  );
};

DeviceSelect.propTypes = {
  selected: PropTypes.string,
  setSelected: PropTypes.func,
};
