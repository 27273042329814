import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { userService } from 'services';
import { Button, ButtonTypes, Input, InputTypes, updateFormErrors, ERRORS, FIELD_VALIDATION } from '@bs/techconnect-ui';
import { ReactComponent as Logo } from 'icons/logo.svg';

import styles from './RecoveryPassword.module.css';

const RecoveryPassword = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const {
    register,
    setError,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm({ mode: 'onTouched' });

  const reset = form => {
    const { email } = form;
    userService.resetUserPassword({ email }).then(({ data, status }) => {
      if (status !== 204) updateFormErrors(data, form, setError);
      if (status === 204)
        auth.signinRedirect({
          extraQueryParams: {
            useremail: `${email}`,
          },
        });
    });
  };
  return (
    <div className={styles['wrapper']}>
      <Logo />
      <header className={styles['title']}>
        <span>techconnect</span> <br /> Tooling
      </header>
      <div className={styles['form']}>
        <header>Восстановление пароля</header>
        <span>
          Для восстановления пароля <b>введите адрес электронной почты,</b> который вы использовали при регистрации
          вашей учетной записи. Мы отправим вам <b>письмо, содержащее одноразовый пароль</b>
        </span>
        <Input
          label="E-mail"
          className={styles['field']}
          variant={InputTypes.M}
          register={register('email', {
            required: ERRORS.REQUIRED('E-mail'),
            pattern: FIELD_VALIDATION.EMAIL,
          })}
          error={errors.email}
        />
        <Button
          className={styles['submit']}
          type="submit"
          disabled={!isValid || isSubmitting}
          onClick={() => handleSubmit(reset)()}
        >
          Отправить пароль
        </Button>
        <Button
          variant={ButtonTypes.S}
          disabled={isSubmitting}
          onClick={() => navigate(-1)}
          className={styles['submit']}
        >
          Отмена
        </Button>
      </div>
    </div>
  );
};

export default RecoveryPassword;
