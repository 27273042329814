import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { fleetService } from 'services';
import { useSuccess } from 'helpers';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { Button, ButtonTypes, ERRORS, Input, updateFormErrors } from '@bs/techconnect-ui';
import { ReactComponent as Back } from 'icons/chevron.svg';

import styles from './FleetPage.module.css';

const FleetPage = () => {
  const { fleetId } = useParams();
  const isCreate = fleetId === 'new';
  const navigate = useNavigate();
  const back = () => navigate('/fleets');
  const { setSuccess } = useSuccess();

  const [loading, setLoading] = useState(false);
  const [exitModal, setExitModal] = useState(false);
  const {
    reset,
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setError,
    trigger,
  } = useForm({ mode: 'onTouched' });

  const { getFleetById, createFleet, updateFleet } = fleetService;
  const fetchFleet = useCallback(() => {
    setLoading(true);
    getFleetById(fleetId)
      .then(({ status, data }) => status === 200 && reset(data))
      .finally(() => setLoading(false));
  }, [getFleetById, fleetId, reset]);
  useEffect(() => {
    if (!isCreate) fetchFleet();
  }, [fetchFleet, isCreate]);

  const submit = form => {
    if (!isValid) {
      trigger();
      return;
    }
    const { name, description } = form;
    const method = isCreate ? createFleet : updateFleet;
    method({ name, description }, fleetId).then(({ data, status }) => {
      if (status !== 200) updateFormErrors(data, form, setError);
      if (status === 200) {
        setSuccess(isCreate ? 'Автопарк создан' : 'Изменения сохранены');
        back();
      }
    });
  };

  return (
    <div className={styles['wrapper']}>
      <header className={styles['header']}>
        <Button
          className={styles['back-btn']}
          variant={ButtonTypes.IR}
          onClick={isDirty ? () => setExitModal(true) : back}
        >
          <Back />
        </Button>

        <h3>{isCreate ? 'Создание' : 'Редактирование'} Автопарка</h3>

        <Button className={styles['submit-btn']} disabled={loading} onClick={() => handleSubmit(submit)()}>
          {isCreate ? 'Создать автопарк' : 'Сохранить изменения'}
        </Button>
      </header>

      <div className={styles['form']}>
        <Input
          label="Название"
          className={styles['form-field']}
          register={register('name', { required: ERRORS.REQUIRED('Название') })}
          error={errors.name}
          disabled={loading}
          autoFocus={true}
        />
        <Input
          label="Комментарий"
          className={styles['form-field']}
          register={register('description')}
          disabled={loading}
        />
      </div>

      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={back} />
    </div>
  );
};

export default FleetPage;
