import { ERRORS, FIELD_VALIDATION } from '@bs/techconnect-ui';
import { ROLES, ROLES_ENUM } from 'helpers';

export const ROLE_LIST = Object.keys(ROLES).map(key => ({ key, title: ROLES[key] }));

export const USER_FORM = [
  {
    key: 'surname',
    title: 'Фамилия',
    options: {
      required: ERRORS.REQUIRED('Фамилия'),
      validate: v => FIELD_VALIDATION.DURATION(v, 1, 130),
    },
  },
  {
    key: 'name',
    title: 'Имя',
    options: {
      required: ERRORS.REQUIRED('Имя'),
      validate: v => FIELD_VALIDATION.DURATION(v, 2, 50),
    },
  },
  {
    key: 'email',
    title: 'E-mail',
    options: {
      required: ERRORS.REQUIRED('E-mail'),
      pattern: FIELD_VALIDATION.EMAIL,
    },
  },
  {
    key: 'phone',
    title: 'Телефон',
    options: {
      required: ERRORS.REQUIRED('Телефон'),
      pattern: FIELD_VALIDATION.PHONE,
    },
  },
];

export const USER_CREATE_ROLES_LIST = ROLE_LIST.filter(
  ({ key }) => key !== ROLES_ENUM.mobile && key !== ROLES_ENUM.IoTAdministrator,
);
