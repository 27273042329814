import { useCallback, useEffect, useState } from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';

import { fleetService } from 'services';
import { COLUMNS } from './helpers';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { Button, ButtonTypes, ListFilters, pageSize, Popper, Table } from '@bs/techconnect-ui';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';

import styles from './Fleets.module.css';

const Fleets = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const fetchFleets = useCallback(() => {
    setLoading(true);
    fleetService
      .getFleets({ page, pageSize })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(data.items);
        }
      })
      .finally(() => setLoading(false));
  }, [page]);

  useEffect(() => {
    fetchFleets();
  }, [fetchFleets]);

  const handler = row => ({
    ...row,
    edit: (
      <Popper popup={'Редактировать'}>
        <NavLink to={`/fleets/${row.id}`}>
          <Button variant={ButtonTypes.IT}>
            <Edit />
          </Button>
        </NavLink>
      </Popper>
    ),
    delete: (
      <Popper popup={'Удалить'}>
        <Button variant={ButtonTypes.IT} onClick={() => setDeleteModal(row.id)}>
          <Delete />
        </Button>
      </Popper>
    ),
  });

  return (
    <>
      <ListFilters title="Автопарки" createHandler={() => navigate('/fleets/new')} updateList={fetchFleets} />

      <Table className={styles['table']} columns={COLUMNS} rows={rows} loading={loading} idKey="id" handler={handler} />

      <ConfirmModal
        open={deleteModal}
        setOpen={() => setDeleteModal(!deleteModal)}
        updateList={fetchFleets}
        method={() => fleetService.deleteFleet(deleteModal)}
        header="Удалить автопарк?"
        description="Я понимаю, что восстановить автотпарка будет невозможно"
      />
    </>
  );
};

export default Fleets;
