import { trimObject } from '@bs/techconnect-ui';
import { createBaseApi, configRequest, prepareQuery } from './baseAPI';

const baseApi = createBaseApi();

export const userService = {
  getSelf,
  getUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  resetUserPassword,
};

function getSelf(body) {
  return baseApi.get('/mobile/users/self', body, configRequest);
}

function getUsers(params = {}) {
  return baseApi.get(`/users?${prepareQuery(params)}`, configRequest);
}

function getUser(id) {
  return baseApi.get(`/users/${id}`, configRequest);
}

function createUser(body) {
  return baseApi.post('/users', trimObject(body), configRequest);
}

function updateUser(body, id) {
  return baseApi.put(`/users/${id}`, trimObject(body), configRequest);
}

function deleteUser(id) {
  return baseApi.delete(`/users/${id}`, configRequest);
}

function resetUserPassword(body) {
  return baseApi.post('/registration/recovery', trimObject(body), configRequest);
}
