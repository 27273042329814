import { NavLink } from 'react-router-dom';
import { NAV_MENU, useUser } from 'helpers';

import styles from './Navigate.module.css';

const Navigate = () => {
  const {
    user: { roles = [] },
  } = useUser();

  const abilityFilter = ({ key, ability = [] }) => {
    if (key === '/') return false;
    const intersection = ability.filter(r => roles.includes(r));
    return !!intersection.length;
  };

  return (
    <nav className={styles['wrapper']}>
      {NAV_MENU.filter(abilityFilter).map(({ key, title }) => (
        <NavLink
          key={key}
          to={key}
          className={({ isActive }) => [isActive && styles['item__active'], styles['item']].join(' ')}
        >
          {title}
        </NavLink>
      ))}
    </nav>
  );
};

export default Navigate;
