import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { vehicleService } from 'services';
import { useSuccess } from 'helpers';
import { processVehicle } from './helpers';
import { Button, ButtonTypes, ButtonGroup, getDate, requestDate, updateFormErrors } from '@bs/techconnect-ui';
import { VehicleForm } from './VehicleForm/VehicleForm';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { VehicleDevice } from './VehicleDevice/VehicleDevice';
import { VehicleOptions } from './VehicleOptions/VehicleOptions';
import { ReactComponent as Back } from 'icons/chevron.svg';

import styles from './VehiclePage.module.css';

const VehiclePage = () => {
  const { vehicleId } = useParams();
  const isCreate = vehicleId === 'new';

  const navigate = useNavigate();
  const back = () => navigate('/vehicles');
  const { setSuccess } = useSuccess();

  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [exitModal, setExitModal] = useState(false);

  // OPTIONS
  const [optionValueForm, setOptionValueForm] = useState(false);
  const [optionsValues, setOptionsValues] = useState([]);

  const { getVehicle, createVehicle, updateVehicle, updateVehicleModel, createVehicleOptionValue } = vehicleService;
  //GET VEHICLE DATA
  const fetchVehicle = useCallback(() => {
    setLoading(true);
    getVehicle(vehicleId)
      .then(({ data, status }) => {
        if (status === 200 && data) setData(processVehicle(data));
      })
      .finally(() => setLoading(false));
  }, [getVehicle, vehicleId]);
  useEffect(() => {
    if (vehicleId && !isCreate) fetchVehicle();
  }, [fetchVehicle, isCreate, vehicleId]);
  const { vin, plateNumber, model, device } = data;
  const { deviceId } = device || {};

  //FORM
  const {
    control,
    trigger,
    reset,
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    setError,
  } = useForm({ mode: 'all' });
  useEffect(() => {
    const saleDate = getDate(data?.saleDate);
    reset({ vin, plateNumber, modelId: model?.id, deviceId, saleDate });
  }, [deviceId, model?.id, plateNumber, reset, data.saleDate, vin]);

  const createOptionValues = id => {
    if (!optionsValues.length) return;
    const promises = optionsValues.map(({ id: optionId, value }) => createVehicleOptionValue({ optionId, value }, id));
    Promise.all(promises).then(results => {
      if (results.every(({ status }) => status === 200)) {
        setSuccess(`Значения опций сохранены`);
      }
    });
  };
  const create = form => {
    const saleDate = requestDate({ date: form.saleDate, tz: '+00:00' }) || null;
    createVehicle({ ...form, saleDate }).then(({ data, status }) => {
      if (status !== 200) updateFormErrors(data, form, setError);
      if (status === 200 && data?.vehicleId) {
        setSuccess(`Транспортное средство создано`);
        createOptionValues(data.vehicleId);
        back();
      }
    });
  };
  const update = form => {
    const body = { ...form, saleDate: requestDate({ date: form.saleDate, tz: '+00:00' }) || null };
    delete body.modelId;
    setLoading(true);
    updateVehicle(body, vehicleId)
      .then(({ status }) => {
        if (status === 200) {
          setSuccess('Изменения сохранены');
          back();
          if (form.modelId !== model?.id) {
            updateVehicleModel({ modelId: form.modelId }, vehicleId).then(({ status }) => {
              if (status === 200) {
                setSuccess('Модель обновлена');
              }
            });
          }
        }
      })
      .finally(() => setLoading(false));
  };
  const submit = isCreate ? create : update;

  const tabHandler = v => {
    if (isCreate && v && !isValid) {
      trigger();
      return;
    }
    setTab(v);
  };

  return (
    <div className={styles['wrapper']}>
      <header className={styles['header']}>
        <div className={styles['header-actions']}>
          <Button
            className={styles['back-btn']}
            variant={ButtonTypes.IR}
            onClick={isDirty ? () => setExitModal(true) : back}
          >
            <Back />
          </Button>

          <h3>{isCreate ? 'Создание' : 'Редактирование'} транспортного средства</h3>

          {isCreate && (
            <Button className={styles['submit-btn']} disabled={loading} onClick={() => handleSubmit(submit)()}>
              Создать TC
            </Button>
          )}
          {!tab && !isCreate && (
            <Button className={styles['submit-btn']} disabled={loading} onClick={() => handleSubmit(submit)()}>
              Сохранить изменения
            </Button>
          )}
          {!!tab && !isCreate && (
            <Button
              variant={ButtonTypes.S}
              className={styles['submit-btn']}
              disabled={loading}
              onClick={() => setOptionValueForm({})}
            >
              Добавить опцию
            </Button>
          )}
        </div>

        <div className={styles['header-tabs']}>
          <ButtonGroup
            className={styles['tabs']}
            buttons={[
              { key: 0, title: 'Параметры' },
              { key: 1, title: 'Опции' },
            ]}
            selected={[tab]}
            onSelect={tabHandler}
          />
          {!!tab && !isCreate && (
            <span className={styles['option-autosave']}>
              Автоматическое сохранение
              <br /> изменений опций
            </span>
          )}
          {!!tab && isCreate && (
            <Button
              variant={ButtonTypes.S}
              className={styles['submit-btn']}
              disabled={loading}
              onClick={() => setOptionValueForm({})}
            >
              Добавить опцию
            </Button>
          )}
        </div>
      </header>

      <div className={styles['body']}>
        {/* ПАРАМЕТРЫ */}
        <VehicleForm
          display={tab ? 'none' : 'flex'}
          control={control}
          register={register}
          errors={errors}
          loading={loading}
          vehicleId={vehicleId}
        />
        <VehicleDevice
          display={tab ? 'none' : 'flex'}
          vehicleId={vehicleId}
          data={data}
          control={control}
          update={() => handleSubmit(submit)()}
          fetchVehicle={fetchVehicle}
          removeDevice={() => updateVehicle({ vin, plateNumber, modelId: model?.id, deviceId: null }, vehicleId)}
        />
        {/* ОПЦИИ */}
        <VehicleOptions
          display={tab ? 'flex' : 'none'}
          vehicleId={vehicleId}
          optionValueForm={optionValueForm}
          setOptionValueForm={setOptionValueForm}
          optionsValues={optionsValues}
          setOptionsValues={setOptionsValues}
        />
      </div>

      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={back} />
    </div>
  );
};

export default VehiclePage;
