import { FilterTypes } from '@bs/techconnect-ui';
import { useEffect } from 'react';

export const AG_TYPES = {
  Dealer: 'Dealer',
  Owner: 'Owner',
  Fleet: 'Fleet',
};

export const FILTERS = [
  { key: 'name', title: 'Название' },
  {
    key: 'rights',
    title: 'Тип доступа',
    placeholder: 'Не выбрано',
    type: FilterTypes.s,
    list: Object.keys(AG_TYPES).map(id => ({ id, title: AG_TYPES[id] })),
  },
];

export const COLUMNS = [
  { key: 'name', title: 'Название' },
  { key: 'rights', title: 'Тип' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const AG_FORM_TABS = { v: 'vehiclesTab', u: 'usersTab' };

export const USER_COLUMNS = [
  { key: 'action', title: '' },
  { key: 'fio', title: 'ФИО' },
  { key: 'phone', title: 'Телефон' },
  { key: 'email', title: 'E-mail' },
];

export const createMethod = (items, setItems, setError, isCreate) => {
  const createHandler = row => {
    if (!isCreate) return;
    const idKey = row.vehicleId ? 'vehicleId' : 'id';
    const isDuplicate = items.some(entity => entity[idKey] === row[idKey]);
    const { agCreate } = row;
    if (!agCreate && isDuplicate) {
      setError('Данный объект уже есть в списке');
      return;
    }
    setItems(prev => {
      return isDuplicate ? prev.filter(entity => entity[idKey] !== row[idKey]) : [{ ...row, agCreate: true }, ...prev];
    });
  };
  // paging AG table
  const usePageHandler = (items, totalPage, setTotalPage, setPageList, page, setPage) => {
    useEffect(() => {
      if (isCreate) {
        if (items.length > 8 * totalPage) {
          setTotalPage(prev => prev + 1);
        } else if (items.length <= 8 * (totalPage - 1) && totalPage > 1) {
          setTotalPage(prev => prev - 1);
          if (page > totalPage - 1) {
            setPage(totalPage - 1);
          }
        }
      }
    }, [items.length, totalPage, setTotalPage, page, setPage]);

    useEffect(() => {
      if (isCreate) {
        const startIndex = (page - 1) * 8;
        const endIndex = page * 8;
        setPageList(items.slice(startIndex, endIndex));
      }
    }, [page, setPageList, items]);
  };
  return { items, setItems, createHandler, usePageHandler };
};
