import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { vehicleService, accessGroupService } from 'services';
import { VEHICLE_COLUMNS, VEHICLE_FILTERS } from 'components/Users/helpers';
import { Table, Input, Button, ButtonTypes, parseBrandModel } from '@bs/techconnect-ui';
import { ReactComponent as Search } from 'icons/search.svg';
import { ReactComponent as Clear } from 'icons/cross.svg';
import { ReactComponent as Add } from 'icons/plus.svg';
import { ReactComponent as Remove } from 'icons/minus.svg';

import styles from '../../AccessGroupForm/AccessGroupForm.module.css';

export const VehicleList = ({
  open = false,
  disabled = false,
  accessGroup = '',
  vehiclesAGCount = 0,
  setVehiclesAGCount = () => {},
  createMethod = () => {},
}) => {
  const [filters, setFilters] = useState({});
  const [filtersInput, setFiltersInput] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  //ALL VEHICLES
  const [vehicles, setVehicles] = useState([]);
  const [vehiclesPage, setVehiclesPage] = useState(1);
  const [vehiclesTotalPages, setVehiclesTotalPages] = useState(1);
  const [vehiclesCount, setVehiclesCount] = useState(0);
  //AG VEHICLES
  const [vehiclesAG, setVehiclesAG] = useState([]);
  const [vehiclesAGPage, setVehiclesAGPage] = useState(1);
  const [vehiclesAGTotalPages, setVehiclesAGTotalPages] = useState(1);
  const [vehiclesAGPageList, setVehiclesAGPageList] = useState([]);

  const fetchVehicle = useCallback(() => {
    vehicleService.getVehicles({ page: vehiclesPage, pageSize: 8, ...filters }).then(({ status, data }) => {
      if (status === 200 && Array.isArray(data?.items)) setVehicles(data.items);
      if (!isNaN(data.totalPages)) setVehiclesTotalPages(data.totalPages);
      if (!isNaN(data.count)) setVehiclesCount(data.count);
    });
  }, [filters, vehiclesPage]);

  const fetchVehicleAG = useCallback(() => {
    accessGroupService
      .getAccessGroupVehicles(accessGroup, { page: vehiclesAGPage, pageSize: 8, ...filters })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) setVehiclesAG(data.items.map(v => ({ ...v, ag: true })));
        if (!isNaN(data.totalPages)) setVehiclesAGTotalPages(data.totalPages);
        if (!isNaN(data.count)) setVehiclesAGCount(data.count);
      });
  }, [accessGroup, filters, setVehiclesAGCount, vehiclesAGPage]);

  useEffect(() => {
    fetchVehicle();
  }, [accessGroup, fetchVehicle, vehiclesPage]);

  useEffect(() => {
    if (accessGroup) fetchVehicleAG();
  }, [accessGroup, vehiclesAGPage, fetchVehicleAG]);

  const applyFilters = () => {
    setVehiclesPage(1);
    setFilters(filtersInput);
  };
  const clearFilters = () => {
    setFiltersInput({});
    setFilters({});
  };
  const handleVehicle = ({ vehicleId, ag }) => {
    setLoading(true);
    const method = ag ? accessGroupService.deleteVehiclesFromAccessGroup : accessGroupService.postVehiclesToAccessGroup;
    method(accessGroup, { vehicles: [vehicleId] })
      .then(({ status }) => {
        if (status === 204) fetchVehicleAG();
      })
      .finally(() => setLoading(false));
  };

  const handler = row => ({
    ...row,
    action: (
      <Button
        variant={ButtonTypes.T}
        onClick={accessGroup ? () => handleVehicle(row) : () => createHandler(row)}
        disabled={loading || disabled}
      >
        {row.ag || row.agCreate ? <Remove /> : <Add />}
      </Button>
    ),
    vin: row.vin,
    brandModel: row.ag ? `${row.brand} ${row.model} ` : parseBrandModel(row.model),
  });

  const { items, createHandler, usePageHandler } = createMethod;
  usePageHandler(
    items,
    vehiclesAGTotalPages,
    setVehiclesAGTotalPages,
    setVehiclesAGPageList,
    vehiclesAGPage,
    setVehiclesAGPage,
  );

  const filterHandler = (key, value, validate) => {
    setFiltersInput({ ...filtersInput, [key]: value });
    setTouched({ ...touched, [key]: true });
    const message = validate && validate(value, filtersInput);
    setErrors(prev => ({ ...prev, [key]: typeof message === 'string' ? message : false }));
  };
  const errorMessage = key => (touched[key] && errors[key] ? { message: errors[key] } : undefined);

  if (!open) return null;

  return (
    <>
      <div className={styles['filters']}>
        {VEHICLE_FILTERS.map(({ key, title, validate }) => (
          <Input
            key={key}
            label={title}
            value={filtersInput[key] || ''}
            onChange={({ target }) => filterHandler(key, target.value, validate)}
            disabled={disabled || loading}
            onEnter={applyFilters}
            className={styles['filters-field']}
            error={errorMessage(key)}
          />
        ))}
        <Button disabled={disabled || loading} variant={ButtonTypes.I} onClick={applyFilters}>
          <Search />
        </Button>

        <Button
          variant={ButtonTypes.T}
          onClick={clearFilters}
          disabled={!Object.keys(filters).length || disabled || loading}
        >
          Сбросить фильтр&nbsp;
          <Clear />
        </Button>
      </div>

      <div className={styles['list']}>
        <div className={styles['list-column']}>
          <header>Все автомобили ({vehiclesCount})</header>
          <Table
            className={styles['table']}
            columns={VEHICLE_COLUMNS}
            loading={loading}
            rows={vehicles}
            totalPages={vehiclesTotalPages}
            setPage={setVehiclesPage}
            page={vehiclesPage}
            idKey="vehicleId"
            handler={handler}
          />
        </div>

        <div className={styles['list-column']}>
          <header>В группе ({accessGroup ? vehiclesAGCount : items.length})</header>
          <Table
            className={styles['table']}
            columns={VEHICLE_COLUMNS}
            loading={loading}
            rows={accessGroup ? vehiclesAG : vehiclesAGPageList}
            totalPages={vehiclesAGTotalPages}
            setPage={setVehiclesAGPage}
            page={vehiclesAGPage}
            idKey="vehicleId"
            handler={handler}
          />
        </div>
      </div>
    </>
  );
};

VehicleList.propTypes = {
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  accessGroup: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  vehiclesAGCount: PropTypes.number,
  setVehiclesAGCount: PropTypes.func,
  createMethod: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};
