import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { iotDevicesService } from 'services';

import { EMPTY } from 'helpers';
import { DEVICE_DATA, CARD_TABS, DEVICE_INFO } from '../helpers';
import { Loader, Button, ButtonTypes, ButtonGroup, ButtonGroupTypes, Paper, PaperTypes } from '@bs/techconnect-ui';
import { ReactComponent as Back } from 'icons/chevron.svg';

import styles from '../IotDevices.module.css';

export const IotDevice = () => {
  const { deviceId } = useParams();

  const [loading, setLoading] = useState(true);
  const [device, setDevice] = useState({ initialConfig: { homeEndpointsValues: {} } });
  const [tab, setTab] = useState('numbers');

  const fetchDevice = deviceId => {
    setLoading(true);
    iotDevicesService
      .getIotDevices({ action: 'getDeviceById', params: { deviceId } })
      .then(({ status, data }) => {
        if (status === 200) setDevice(data);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchDevice(deviceId);
  }, [deviceId]);

  const getValue = path => {
    const steps = path.split('.');
    return steps.reduce((acc, key) => {
      return acc[key] === null || acc[key] === undefined ? EMPTY : acc[key];
    }, device);
  };
  return (
    <div className={styles['card']}>
      {loading && <Loader />}
      {!loading && (
        <>
          <header className={styles['header']}>
            <NavLink to="/iotDevices">
              <Button variant={ButtonTypes.IR}>
                <Back />
              </Button>
            </NavLink>
            Карточка устройства
          </header>
          <div className={styles['device-info']}>
            {DEVICE_INFO.map((column, columnIndex) => (
              <div key={'device-column-' + columnIndex} className={styles['device-column']}>
                {column.map((item, itemIndex) => (
                  <div key={device[item.key] || 'device-item-' + itemIndex} className={styles['device-item']}>
                    <div className={styles['device-title-' + columnIndex]}>{item.title}</div>
                    <div className={styles['device-data']}>{device[item.key] || EMPTY}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <ButtonGroup
            variant={ButtonGroupTypes.U}
            className={styles['tabs']}
            buttons={Object.keys(CARD_TABS).map(key => ({ key, title: CARD_TABS[key] }))}
            selected={[tab]}
            onSelect={setTab}
            disabled={loading}
          />
          <div className={styles['info-wrapper']}>
            {DEVICE_DATA[tab].map((col, colIndex) => (
              <Paper key={'col-' + tab + colIndex} variant={PaperTypes.LM} className={styles['info']}>
                {col.map(({ key, title }) => (
                  <div key={'row-' + key} className={styles['info-row']}>
                    <div className={styles['info-title']}>{title}</div>
                    <div className={styles['info-data']}>{getValue(key)?.toString()}</div>
                  </div>
                ))}
              </Paper>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
