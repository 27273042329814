import { useCallback, useEffect, useState } from 'react';
import { useSearchParams, NavLink, useNavigate } from 'react-router-dom';

import { vehicleService } from 'services';
import { useSuccess } from 'helpers';
import { COLUMNS, FILTERS } from './helpers';

import {
  Copy,
  Popper,
  Table,
  Button,
  ButtonTypes,
  ListFilters,
  separateBrandModel,
  pageSize,
  shortString,
} from '@bs/techconnect-ui';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { VehicleCommands } from './VehicleCommands/VehicleCommands';
import { VehicleTelemetry } from './VehicleTelemetry/VehicleTelemetry';
import { VehicleExport } from './VehicleExport/VehicleExport';
import { ReactComponent as Commands } from 'icons/commands.svg';
import { ReactComponent as Telemetry } from 'icons/telemetry.svg';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as Export } from 'icons/provisioning.svg';

import styles from './Vehicles.module.css';

const Vehicles = () => {
  const navigate = useNavigate();
  const { setSuccess } = useSuccess();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  //СПИСОК
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [open, setOpen] = useState(false);

  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  //КОММАНДЫ
  const [commandsModal, setCommandsModal] = useState('');
  //ТЕЛЕМЕТРИЯ
  const [telemetryModal, setTelemetryModal] = useState('');
  //УДАЛЕНИЕ
  const [deleteModal, setDeleteModal] = useState(false);
  //Карточка устройства

  const action_items = [
    {
      key: 'exportVehicle',
      element: (
        <Popper popup={'Экспорт созданных ТС'}>
          <Button variant={ButtonTypes.I} onClick={() => setOpen(!open)}>
            <Export />
          </Button>
        </Popper>
      ),
    },
  ];

  const fetchVehicles = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem(`vehiclesFilters`) || '{}') || {};
    const { filter = false, ...queryFilter } = filterData;
    setLoading(true);
    vehicleService
      .getVehicles({ page, pageSize, ...(filter && queryFilter) })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(
            data.items.map((vehicle = {}) => {
              const { model, brand, plant } = separateBrandModel(vehicle?.model);
              const esn = vehicle?.device?.esn;
              const deviceId = vehicle?.device?.deviceId;
              return { ...vehicle, model, brand, esn, plant, deviceId };
            }),
          );
          if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [page, filters]);

  useEffect(() => {
    fetchVehicles();
  }, [page, filters, fetchVehicles]);

  const handler = row => ({
    ...row,
    id: (
      <Copy value={row.vehicleId} callback={() => setSuccess('Данные скопированы')}>
        {shortString(row.vehicleId)}
      </Copy>
    ),
    vin: (
      <Copy value={row.vin} callback={() => setSuccess('Данные скопированы')}>
        {row.vin}
      </Copy>
    ),
    plateNumber: (
      <Copy value={row.plateNumber} callback={() => setSuccess('Данные скопированы')}>
        {row.plateNumber}
      </Copy>
    ),
    deviceId: (
      <Copy value={row.deviceId} callback={() => setSuccess('Данные скопированы')}>
        <Button variant={ButtonTypes.T} onClick={() => navigate(`/devices/${row?.deviceId}`)}>
          {shortString(row.deviceId)}
        </Button>
      </Copy>
    ),
    esn: (
      <Copy value={row.esn} callback={() => setSuccess('Данные скопированы')}>
        <Button variant={ButtonTypes.T} onClick={() => navigate(`/devices/${row?.deviceId}`)}>
          {row.esn}
        </Button>
      </Copy>
    ),
    owner: (
      <Button variant={ButtonTypes.T} onClick={() => navigate(`/users/${row?.owner?.id}`)}>
        {row.owner?.email}
      </Button>
    ),
    commands: (
      <Popper popup={'Команды'}>
        <Button className={styles['commands']} variant={ButtonTypes.IT} onClick={() => setCommandsModal(row)}>
          <Commands />
        </Button>
      </Popper>
    ),
    telemetry: (
      <Popper popup={'Телеметрия'}>
        <Button
          disabled={!row.deviceId}
          variant={ButtonTypes.IT}
          onClick={() => setTelemetryModal({ deviceId: row.deviceId, vehicleId: row.vehicleId })}
        >
          <Telemetry />
        </Button>
      </Popper>
    ),
    edit: (
      <Popper popup={'Перейти в карточку ТС'}>
        <NavLink to={`/vehicles/${row.vehicleId}`}>
          <Button variant={ButtonTypes.IT}>
            <Edit />
          </Button>
        </NavLink>
      </Popper>
    ),
    delete: (
      <Popper popup={'Удалить'}>
        <Button variant={ButtonTypes.IT} onClick={() => setDeleteModal(row.vehicleId)}>
          <Delete />
        </Button>
      </Popper>
    ),
  });

  return (
    <>
      <ListFilters
        title="Транспортные средства"
        fields={FILTERS}
        filters={filters}
        setFilters={setFilters}
        createHandler={() => navigate('/vehicles/new')}
        actions={action_items}
      />
      <Table
        className={styles['table']}
        columns={COLUMNS}
        rows={rows}
        totalPages={totalPages}
        loading={loading}
        idKey="vehicleId"
        handler={handler}
      />
      <ConfirmModal
        open={deleteModal}
        setOpen={() => setDeleteModal(!deleteModal)}
        updateList={fetchVehicles}
        method={() => vehicleService.deleteVehicle(deleteModal)}
        header="Удалить транспортное средство?"
        description="Я понимаю, что восстановить транспортное средство будет невозможно"
      />
      {commandsModal && <VehicleCommands vehicle={commandsModal} setOpen={() => setCommandsModal('')} />}
      {telemetryModal && (
        <VehicleTelemetry
          deviceId={telemetryModal?.deviceId}
          vehicleId={telemetryModal?.vehicleId}
          setOpen={() => setTelemetryModal('')}
        />
      )}
      <VehicleExport open={open} setOpen={setOpen} />
    </>
  );
};

export default Vehicles;
