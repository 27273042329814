import { FIELD_VALIDATION, FilterTypes } from '@bs/techconnect-ui';
import { ROLES } from 'helpers';

export const ROLE_LIST = Object.keys(ROLES).map(key => ({ key, title: ROLES[key] }));

const ROLE = {
  key: 'roles',
  title: 'Роль',
  placeholder: 'Не выбрано',
  type: FilterTypes.ms,
  list: ROLE_LIST,
};

export const FILTERS = [
  {
    key: 'search',
    title: 'Фамилия / Имя / E-mail / Телефон',
    validate: v => !v || FIELD_VALIDATION.DURATION(v, 3, 100),
  },
  ROLE,
];

export const COLUMNS = [
  { key: 'surname', title: 'Фамилия' },
  { key: 'name', title: 'Имя' },
  { key: 'email', title: 'E-mail' },
  { key: 'phone', title: 'Телефон' },
  ROLE,
  { key: 'plant', title: 'Автопроизводитель' },
  { key: 'dealer', title: 'Дилерский центр' },
  { key: 'fleet', title: 'Автопарк' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const VEHICLE_FILTERS = [
  { key: 'vin', title: 'VIN', validate: v => !v || FIELD_VALIDATION.DURATION(v, 3, 17) || '' },
  { key: 'plateNumber', title: 'Гос. Номер', validate: v => !v || FIELD_VALIDATION.DURATION(v, 3, 10) },
];

export const VEHICLE_COLUMNS = [
  { key: 'action', title: '' },
  { key: 'vin', title: 'VIN' },
  { key: 'plateNumber', title: 'Гос. номер' },
  { key: 'brandModel', title: 'Бренд и модель' },
];
